<template>
  <div class="mx-auto" style="max-width: 300px;">
    <h2>Registrarse</h2>
    <hr/>
    <b-form @submit="register">
        <b-form-group id="input-group-name" label="Nombre" label-for="input-name" >
            <b-form-input id="input-name" v-model="form.first_name" required placeholder="Nombres"></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-lastname" label="Nombre" label-for="input-lastname" >
            <b-form-input id="input-lastname" v-model="form.last_name" required placeholder="Apellidos"></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-email" label="Correo electrónico" label-for="input-email" class="my-4">
            <b-form-input id="input-email" v-model="form.email" type="email" required placeholder="Email"></b-form-input>
        </b-form-group>

      <b-form-group id="input-group-username" label="Usuario" label-for="input-username">
        <b-form-input id="input-username" v-model="form.username" required placeholder="Usuario"></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-password" label="Clave" label-for="input-password">
        <b-form-input id="input-password" v-model="form.password" type="password" required placeholder="Clave"></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-password-repeated" label="Repetir Clave" label-for="input-password-repeated"
        description="Las contraseñas deben coincidir." v-if="register"
      >
        <b-form-input id="input-password-repeated" v-model="form.passwordRepeated" type="password" required
          placeholder="Clave"
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary">Aceptar</b-button>
    </b-form>
    <b-card class="mt-3" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import router from '@/router/index'
export default {
    data() {
        return {
            form: {
                username: '',
                email: '',
                first_name: '',
                last_name: '',
                password: '',
                passwordRepeated: '',
            },
        }
    },
    methods: {
        register(event) {
          event.preventDefault()
          axios.post('http://127.0.0.1:8000/api/v1.0/users/', this.form)
          .then(() => {
              router.replace('/login', () => {}) //Se captura el error de navegación duplicada.
          })
          .catch((error) => {
              console.log(error)
          })
        },
    },
}
</script>
